import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  map: {
    height: 'calc(100vh - 64px)',
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    marginLeft: theme.spacing(2),
    alignSelf: 'center',
  },
  shape: {
    maxWidth: '32px',
    maxHeight: '32px',
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    borderRadius: '50px',
    alignSelf: 'center',
    position: 'relative',
    marginRight: theme.spacing(2),
  },
  shapeWithLine: {
    '&::before': {
      content: "''",
      height: '64px',
      width: '1px',
      opacity: 0.4,
      position: 'absolute',
      background: theme.palette.primary.main,
      margin: '0 auto',
    },
  },
  svg: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fill: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  embedWindow: {
    overflow:'none',
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid #d6d6d6',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: '4px 14px 4px 14px',
  },
})(MuiAccordionSummary);

export { useStyles, Accordion, AccordionSummary };
