import React, { useContext } from 'react';

import { Drawer as MUIDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DrawerContext } from '../../context/DrawerContext';

const drawerWidth = 375;

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    background: theme.palette.secondary[50],
    width: drawerWidth,
    paddingTop: theme.spacing(8),
    zIndex: 2,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
}));

const Drawer = () => {
  const classes = useStyles();
  const { isOpen, close } = useContext(DrawerContext);

  return (
    <MUIDrawer
      open={isOpen}
      anchor="right"
      onClose={close}
      variant="persistent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {/* DrawerPortal will insert content into #drawer-content below */}
      <div id="drawer-content" />
    </MUIDrawer>
  );
};

export default Drawer;
