import React from 'react';


import {
    Box,
    Dialog,
    DialogTitle,
    Button,
    Typography
} from '@material-ui/core'

const ConfirmationBox = ({state, context, accept, decline}) => {


    return(
        <Box m={4} width="auto">
            <Dialog disableBackdropClick aria-labelledby="simple-dialog-title" open={state}>
                <DialogTitle id="simple-dialog-title">Confirm action</DialogTitle>
                <Box alignItems="center" style={{ margin: 8 }} >
                    <Typography>{context}</Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 8 }} 
                        onClick={()=>{accept()}}
                    >
                        OK
                    </Button>
                    <Button 
                        variant="contained"
                        color="secondary"
                        style={{ margin: 8}} 
                        onClick={()=>{decline()}}
                    >
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </Box>
    )
}


export default ConfirmationBox