import React, { useEffect, useContext } from 'react';

import { DrawerContext } from '../../context';
import { Typography, Box, Paper, Link } from '@material-ui/core';

const About = () => {
  const { close, isOpen } = useContext(DrawerContext);

  // Disable drawer while the page is empty
  useEffect(() => {
    if (isOpen) {
      close();
    }
  }, [isOpen]);

  return (
    <Box m={4} width="auto" display="flex" flexDirection="column">
      <Paper>
        <Box p={4}>
          <Typography variant="h5">
            About DHI's Power BI Portal
          </Typography>

          <Box my={2}>
            <Typography variant="h6">What:</Typography>
            <Typography variant="body1">
            DHI’s Power BI Portal is a DHI service for accessing Power BI reports and dashboard hosted by DHI for external users.
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="h6">How:</Typography>
            <Typography variant="body1">
              To get access to our own organizations Power BI dashboards and reports please contact your company administrator to become member. 
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="h6">Who:</Typography>
            <Typography variant="body1">
                For further information and support requests please contact <Link href="mailto:mike@dhigroup.com?subject=Power BI Portal">mike@dhigroup.com</Link>.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default About;
