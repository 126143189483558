import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';

import DarkLogo from '../../assets/img/DHI_Logo_Dark.png';

const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: '1rem',
  },
  button: {
    alignSelf: 'start',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));

const LoginForm = ({ handleAuth, loading }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    error: false,
    email: '',
    password: '',
  });

  const handleSubmit = async e => {
    e.preventDefault();
    handleAuth({ emailAddress: state.email, password: state.password });
  };

  return (
    <Grid
      style={{ height: '100%' }}
      container
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={4}>
        <Paper>
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            p={5}
            onSubmit={handleSubmit}
          >
            <div className={classes.logo}>
              <img alt="DHI Logo" src={DarkLogo} />
            </div>
            <TextField
              variant="outlined"
              className={classes.input}
              onChange={e => setState({ ...state, email: e.target.value })}
              value={state.email}
              label="Email"
              required
            />
            <TextField
              error={!!state.error}
              variant="outlined"
              className={classes.input}
              onChange={e => setState({ ...state, password: e.target.value })}
              value={state.password}
              label="Password"
              type="password"
              required
              helperText={state.error}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              fullWidth
              disabled={loading}
              startIcon={loading && <CircularProgress size={16} />}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
