import React, { createContext, useState } from 'react';

import { Snackbar, SnackbarContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const initialState = {
  open: false,
  message: '',
  variant: 'info',
  type: '',
  setOpen: () => {},
  setClosed: () => {},
};

const NotificationContext = createContext(initialState);

const NotificationProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setOpen = (message, variant = 'info') =>
    setState({
      ...state,
      open: true,
      message,
      variant,
    });

  const setClosed = () => setState({ ...state, open: false, message: '' });

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        setOpen,
        setClosed,
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={state.open}
        variant={state.variant}
        autoHideDuration={5000}
        onClose={setClosed}
      >
        <SnackbarContent
          aria-describedby="message-id"
          message={state.message}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={setClosed}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

const NotificationConsumer = NotificationContext.Consumer;

export { NotificationContext, NotificationProvider, NotificationConsumer };
