import React, { useRef, useEffect} from 'react'
//import { models, Report, Embed, IEmbedConfiguration, service, Page } from 'powerbi-client'
import * as pbi from 'powerbi-client'

export default function PowerBIEmbedded({props}) {
    const embedContainerRef = useRef(null);
    
    useEffect(() => {
        if(props){
            // Get models. models contains enums that can be used.
            const { models } = pbi;
        
            // Embed Token
        
            // Set required report permissions
            const permissions = models.Permissions.All;
        
        
            // Embed configuration used to describe the what and how to embed.
            // This object is used when calling powerbi.embed.
            // This also includes settings and options such as filters.
            // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.

            const config = {
                type: 'report',
                id: props.id,
                embedUrl: props.embedUrl,
                accessToken: props.embedToken.token, // this is the embed token obtained from the API
                tokenType: models.TokenType.Embed,
                permissions,
                pageView: "fitToWidth",
                settings: {
                    background: models.BackgroundType.Transparent,
                    filterPaneEnabled: false,
                    navContentPaneEnabled: true,
                }
            };
        
            // Create the powerbi instance
            const powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory,
            );
        
            // Reset the report in case there was one displayed previously.
            powerbi.reset(embedContainerRef.current);
        
            // Embed the report and display it within the div container.
            powerbi.embed(embedContainerRef.current, config)
            embedContainerRef.current.style.height = "90vh"
            embedContainerRef.current.style.width = "100vw"
        }
    },[props])

    // useEffect(() => {
        
    // })

    return (
        <div ref={embedContainerRef} />
    )
}
