import React from 'react';

const Logout = ({ setAuth }) => {

    setAuth(false);
    sessionStorage.clear();

    return (
        <></>
    );
};

export default Logout;
