import React from 'react';
import ReactDOM from 'react-dom';

class DrawerPortal extends React.Component {
  constructor(props) {
    super(props);
    this.drawer = null;
    this.container = document.createElement('div');
  }

  componentDidMount() {
    this.drawer = document.getElementById('drawer-content');
    this.drawer.appendChild(this.container);
  }

  componentWillUnmount() {
    this.drawer.removeChild(this.container);
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(<div>{children}</div>, this.container);
  }
}

export default DrawerPortal;
