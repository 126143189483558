import React, { createContext, useContext, useEffect, useState } from 'react';
import { Switch, Route, Link, Redirect, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import {
  Auth,
  Admin,
  SuperAdmin,
  About,
  Portal,
  Logout
} from '../../containers';
import { Drawer } from '../../components';

import { DrawerContext } from '../../context';

import DHILogo from '../../assets/img/logo.png';

const appName = process.env.REACT_APP_APP_NAME;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: 3,
  },
  content: {
    flexGrow: 1,
    height: '100%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    height: '64px',
  },
  account: {
    marginLeft: 'auto',
    marginRight: 0,
    zIndex: '2',
  },
  accountDropdown: {
    zIndex: '2',
  },
  menuButton: {
    marginLeft: 'auto',
    alignSelf: 'center',
    marginRight: -theme.spacing(2), // Offset toolbar padding
  },
  dhiLogo: {
    width: '2rem',
    height: 'auto',
    marginLeft: '0.5rem',
  },
  tab: {
    height: '64px',
  },
}));

const Tabs = withStyles(() => ({
  root: {
    height: '64px',
  },
  indicator: {
    backgroundColor: '#fff',
    height: '0.15rem',
  },
}))(MuiTabs);

const Tab = withStyles(() => ({
  root: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  selected: {
    fontWeight: 'bold',
  },
}))(MuiTab);

export const AppContext = createContext();

// const tabs = [
//   {
//     value: '/portal',
//     label: 'Portal',
//     to: '/portal',
//     visible: true
//   },
//   {
//     value: '/admin',
//     label: 'Admin panel',
//     to: '/admin',
//     visible: true
//   },
//   { 
//     value: '/about',
//     label: 'About Power BI Portal',
//     to: '/about',
//     visible:true
//   },
// ];

const App = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isOpen, close, open } = useContext(DrawerContext);
  const [auth, setAuth] = useState(false);
  const [adm, setAdm] = useState(false);
  const [role, setRole] = useState();
  const [tabs, setTabs] = useState([]);

    const publicTabs = [
    { 
      value: '/about',
      label: 'About Power BI Portal',
      to: '/about',
      visible:true
    },
    { 
      value: '/login',
      label: 'Log in',
      to: '/login',
      visible:true
    }
  ];

  useEffect(() => {
    !auth ? history.push('/login') : history.push('/portal');
    var role = sessionStorage.getItem('role');
    var opt = role == 'Admin' | role == 'SuperAdmin'
    setAdm(opt)
    setRole(role);
    if(auth){
      setTabs([
        {
          value: '/portal',
          label: 'Portal',
          to: '/portal',
          visible: true
        },
        {
          value: '/admin',
          label: 'Admin panel',
          to: '/admin',
          visible: opt
        },
        { 
          value: '/about',
          label: 'About Power BI Portal',
          to: '/about',
          visible:true
        },
        { 
          value: '/logout',
          label: 'Log out',
          to: '/logout',
          visible:true
        },
      ])
    } else {
      setTabs(publicTabs)
    }
  },[auth]);

  useEffect(()=>{
    close();
  },[])

  return (
    <AppContext.Provider value={{ actions: { setAuth }, state: { auth } }}>
      <Box>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h6" noWrap>
                  {appName}
                </Typography>
                <Box display="flex" alignItems="baseline">
                  <Typography>Powered by</Typography>
                  <img
                    className={classes.dhiLogo}
                    src={DHILogo}
                    alt="logo"
                    id="logo"
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" ml="auto" mr={0}>
              <Tabs
                value={
                  history.location.pathname
                }
              >
                {tabs.map(tab => (
                  tab.visible ?
                  <Tab
                    key={tab.value}
                    className={classes.tab}
                    value={tab.value}
                    label={tab.label}
                    component={Link}
                    to={tab.to}
                    // disabled={!auth}
                  /> : 
                  null
                ))}
              </Tabs>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                className={classes.menuButton}
                onClick={() => (isOpen ? close() : open())}
                disabled={!auth}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Switch>
            <Route path="/login">
              <Auth setAuth={setAuth} />
            </Route>
            <Route path="/portal">
              <Portal auth={auth} role={role}/>
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/admin">
              <Admin role={role} />
            </Route>
            <Route path="/logout">
              <Logout setAuth={setAuth} />
            </Route>
            <Redirect to="/portal" />
          </Switch>
          <Drawer />
        </main>
      </Box>
    </AppContext.Provider>
  );
};

export default App;
