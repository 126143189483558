import React, { useEffect, useReducer } from 'react'

import {
    Button,
    LinearProgress,
} from '@material-ui/core'

import { DataGrid, GridOverlay } from '@material-ui/data-grid';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { ConfirmationBox } from '../../../components';

import UserForm from './UserForm';

import reducer from './userReducer';

import restClient from '../../../services/apiClient'
var client = new restClient();

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

const UserPanel = () => {
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        users: [],
        selectedUser: undefined,
        tenancyOptions: [],
        roleOptions: [],
        panels: {
            editUser: false,
            createUser: false,
            confirmationBox: false
        }
    })

    const formatRole = (params) => {
        return params.value.roleName;
    }

    const formatTenancy = (params) => {
        return params.value.map(x => { return x.name })
    }

    const refreshUserList = () => {
        dispatch({ name: 'toggleLoading' })
        getUsers(users => {
            dispatch({ name: 'updateUserList', payload: users })
            dispatch({ name: 'toggleLoading' })
        });
    }

    // Load initial information
    useEffect(() => {
        getUsers(users => {
            dispatch({ name: 'updateUserList', payload: users })
            dispatch({ name: 'toggleLoading' })
        });
        getRoleOptions(roles => {
            dispatch({ name: 'updateRoleOptions', payload: roles })
        })
        getTenancies(tenancy => {
            dispatch({ name: 'updateTenancyOptions', payload: tenancy })
        })
    }, [])

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        dispatch({ name: 'TogglePanel', payload: { panel: 'createUser', value: undefined } })
                    }}
                >
                    Create New User
                </Button>
            </div>
            <div style={{ height: '72vh', width: '80vw' }}>
                <DataGrid
                    components={{
                        LoadingOverlay: CustomLoadingOverlay,
                    }}
                    loading={state.loading}
                    rows={state.users}
                    columns={
                        [{ field: 'id', headerName: 'ID', width: 70 },
                        { field: 'firstName', headerName: 'First name', width: 130 },
                        { field: 'lastName', headerName: 'Last name', width: 130 },
                        { field: 'username', headerName: 'Username', width: 190 },
                        { field: 'role', headerName: 'Role', width: 130, valueFormatter: formatRole },
                        { field: 'tenancies', headerName: 'Tenancies', width: 150, valueFormatter: formatTenancy },
                        {
                            field: 'approved', headerName: 'Access', width: 190,
                            renderCell: (params) => {
                                if (params.value) {
                                    return (<CheckCircleOutlineIcon style={{ fill: 'Green' }} />)
                                } else {
                                    return (<HighlightOffIcon style={{ fill: 'Red' }} />)
                                }
                            }
                        },
                        {
                            field: 'options', headerName: 'Options', width: 300,
                            valueGetter: (params) => {
                                return params.getValue('id');
                            },
                            renderCell: (params) => {
                                return (
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: 16 }}
                                            size="small"
                                            onClick={() => {
                                                dispatch({ name: 'TogglePanel', payload: { panel: 'editUser', value: params.value } })
                                            }}>Edit</Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginRight: 16 }}
                                            size="small"
                                            disabled={sessionStorage.getItem("userName") === params.row.username}
                                            onClick={() =>
                                                dispatch({ name: 'TogglePanel', payload: { panel: 'confirmationBox', value: params.value } })
                                            }>Delete</Button>
                                    </div>
                                )
                            }
                        }]
                    }
                    pageSize={20}
                    disableSelectionOnClick />
            </div>
            <ConfirmationBox
                state={state.panels.confirmationBox}
                context="Are you sure you want to delete the user?"
                accept={() => {
                    deleteUser(state.selectedUser, () => {
                        refreshUserList()
                        dispatch({ name: 'TogglePanel', payload: { panel: 'confirmationBox', value: undefined } })
                    });
                }}
                decline={() => {
                    dispatch({ name: 'TogglePanel', payload: { panel: 'confirmationBox', value: undefined } })
                }}
            />


            <UserForm
                state={state.panels.createUser}
                options={{
                    tenancies: state.tenancyOptions,
                    roles: state.roleOptions,
                    title: 'Create user'
                }}
                ok={(userObject) => {
                    createUser(userObject, () => {
                        refreshUserList()
                        dispatch({ name: 'TogglePanel', payload: { panel: 'createUser', value: undefined } })
                    });
                }}
                cancel={() => {
                    dispatch({ name: 'TogglePanel', payload: { panel: 'createUser', value: undefined } })
                }}
            />

            <UserForm
                state={state.panels.editUser}
                options={{
                    tenancies: state.tenancyOptions,
                    roles: state.roleOptions,
                    user: state.selectedUser,
                    title: 'Edit user'
                }}
                ok={(userObject) => {
                    updateUser(userObject, () => {
                        refreshUserList()
                        dispatch({ name: 'TogglePanel', payload: { panel: 'editUser', value: undefined } })
                    });
                }}
                cancel={() => {
                    dispatch({ name: 'TogglePanel', payload: { panel: 'editUser', value: undefined } })
                }}
            />
        </div>
    )
}

const getUsers = async (callback) => {
    try {
        const response = await client.userGet();

        if (response.status == 200) {
            callback(response.data)
        }

    } catch (ex) {
        console.error(ex)
    }
}

const getRoleOptions = async (callback) => {
    try {
        const response = await client.rolesGet();

        if (response.status == 200) {
            callback(response.data)
        }

    } catch (ex) {
        console.error(ex)
    }
}

const getTenancies = async (callback) => {
    try {
        const response = await client.tenancyGet();

        if (response.status == 200) {
            callback(response.data)
        }

    } catch (ex) {
        console.error(ex)
    }
}

const createUser = async (userObj, callback) => {
    try {
        const response = await client.userCreate(userObj);

        if (response.status != 201) {
            //throw
        }
        callback()

    } catch (ex) {
        console.error(ex)
    }
}

const updateUser = async (userObj, callback) => {
    try {
        const response = await client.userUpdate(userObj);

        if (response.status != 201) {
            //throw
        }
        callback()
    } catch (ex) {
        console.error(ex)
    }
}


const deleteUser = async (userObj, callback) => {
    try {
        const response = await client.userDelete(userObj.id);

        if (response.status != 204) {
            //throw
        }
        callback()

    } catch (ex) {
        console.error(ex)
    }
}

export default UserPanel