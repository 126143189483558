import React, {
    useEffect,
    useReducer,
    useContext,
} from 'react';

import {
    Box,
    Paper,
    Divider,
    TextField,
    MenuItem,
} from '@material-ui/core';

import findDeep from 'deepdash/findDeep';

import { DrawerContext, NotificationContext } from '../../context';
import { DrawerPortal } from '../../components';
import { PowerBIEmbedded } from '../../components';

import reducer from './partials/reducer';
import { useStyles } from './partials/styles';
import EmptyStateMessage from './partials/EmptyStateMessage';

import restClient from '../../services/apiClient'
var client = new restClient();


const Portal = ({ auth, role }) => {
    const classes = useStyles();
    const { close, open, isOpen } = useContext(DrawerContext);
    const { setOpen } = useContext(NotificationContext);
    const [state, dispatch] = useReducer(reducer, {
        embedInfo: undefined,
        workspace:'',
        tenancy:'',
        report:'',
        selected: undefined
    })

    useEffect(() => {
        open();
    }, [])

    useEffect(() => {
        let isSubscribed = true;
        if (auth) {
            dispatch({ name: 'toggleLoading', loading: true });
            fetchData((response) => {
                if(isSubscribed){
                    dispatch({
                        name: 'setEmbedInfo', payload:
                        {
                            id: 'root',
                            name: 'Tenancies',
                            children: response.data.embedConfigs
                        }
                    })
                }
            })
        };
        return() => isSubscribed = false
    }, [auth])

    useEffect(()=>{
        if(state.embedInfo){
            dispatch({ name: 'toggleLoading', loading: false });
            handleTenancyChange({
                name:state.embedInfo.children[0].id,
                value:state.embedInfo.children[0].name
            })
        }
    },[state.embedInfo])

 

    const fetchData = async (callback) => {
        try {
            var response = await client.getEmbedInfo();

            if (response.status !== 200) {
                setOpen(response.body)
                callback(null)
            }

            if (response.data.errorMessage) {
                setOpen(response.data.errorMessage)
                callback(null)
            }
            callback(response)
        } catch (ex) {
            setOpen('Error fetching embed info.')
            callback(null)
        } 
    }

    const handleTenancyChange = (selection) => {
        dispatch({name:'clearSelectedReport'})
        dispatch({name:'updateSelectedTenancy',payload:selection.value})
        var result = getNestedObjectById(state.embedInfo,selection.name)
        dispatch({name:'setWorkspaceObj',payload: result.value})
        handleWorkspaceChange({name:result.value.children[0].id,value:result.value.children[0].name})
    }

    const handleWorkspaceChange = (selection) => {
        dispatch({name:'updateSelectedWorkspace',payload:selection.value})
        var result = getNestedObjectById(state.embedInfo,selection.name)
        dispatch({name:'setReportObj',payload: result.value})
    }

    const handleSelection = (selection) => {
        dispatch({name:'updateSelectedReport',payload:selection.value})
        var result = getNestedObjectById(state.embedInfo,selection.name)
        dispatch({name: 'setSelection', payload: result.value})
        close();
    }

    return (
        <>
            <DrawerPortal>
                <Paper className={classes.paper}>
                    {state.embedInfo === undefined ? (
                        <EmptyStateMessage />
                    ) : (
                            <Box display="flex" flexDirection='column'>
                                {
                                    //role === 'SuperAdmin' ?
                                    state.embedInfo.children.length > 1 ?
                                    <TextField
                                        select
                                        value={state.tenancy}
                                        variant="outlined"
                                        className={classes.input}
                                        label="Tenancy"
                                        onChange={(event,obj)=> {event.preventDefault(); handleTenancyChange(obj.props)}}
                                        fullWidth
                                    >
                                        {
                                            state.embedInfo.children.map((obj,i)=>{
                                                return(
                                                    <MenuItem value={obj.name} key={obj.id} name={obj.id}>{obj.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                    :
                                    <div></div>
                                }
                                    <Divider className={classes.divider} orientation="vertical" />
                                {
                                    state.embedInfo.children.length > 1 ?
                                    <TextField
                                        select
                                        disabled={state.workspaceObj === undefined}
                                        value={state.workspace}
                                        variant="outlined"
                                        className={classes.input}
                                        label="Workspace"
                                        onChange={(event,obj)=> {event.preventDefault(); handleWorkspaceChange(obj.props)}}
                                        fullWidth
                                    >
                                        {
                                            state.workspaceObj ? state.workspaceObj.children.map((obj,i)=>{
                                                return(
                                                    <MenuItem value={obj.name} key={obj.id} name={obj.id}>{obj.name}</MenuItem>
                                                )
                                            }) : <MenuItem value='dummy'></MenuItem>
                                        }
                                    </TextField>
                                    :
                                    <></>
                                }

                                <Divider className={classes.divider} orientation="vertical" />
                                <TextField
                                    select
                                    disabled={state.reportObj === undefined}
                                    value={state.report}
                                    variant="outlined"
                                    className={classes.input}
                                    label="Report"
                                    onChange={(event,obj)=> {event.preventDefault(); handleSelection(obj.props)}}
                                    fullWidth
                                >
                                    {
                                        state.reportObj ? state.reportObj.children.map((obj,i)=>{
                                            return(
                                                <MenuItem value={obj.name} key={obj.id} name={obj.id}>{obj.name}</MenuItem>
                                            )
                                        }) : <MenuItem value='dummy'></MenuItem>
                                    }
                                </TextField>
                            </Box>
                        )}
                </Paper>
            </DrawerPortal>
            <PowerBIEmbedded className={classes.embedWindow} props={state.selected} />
        </>
    );
};

export default Portal;


const getNestedObjectById = (obj, id) =>
    findDeep(obj, (child) => { return child.id === id}, {
        childrenPath: "children"
    }
);
