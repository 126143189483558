const reducer = (state, action) => {
    switch (action.name) {

        case 'toggleLoading':
            return {
                ...state,
                loading: action.loading,
            };

        case 'setEmbedInfo': {
            return {
                ...state,
                embedInfo: action.payload
            };
        }

        case 'setSelection': {
            return {
                ...state,
                selected: action.payload
            }
        }

        case 'updateSelectedTenancy': {
            return {
                ...state,
                tenancy: action.payload
            }
        }
        case 'updateSelectedWorkspace': {
            return {
                ...state,
                workspace: action.payload
            }
        }
        case 'updateSelectedReport': {
            return {
                ...state,
                report: action.payload
            }
        }

        case 'setWorkspaceObj': {
            return {
                ...state,
                workspaceObj: action.payload
            }
        }

        case 'setReportObj': {
            return {
                ...state,
                reportObj: action.payload
            }
        }

        case 'clearSelectedReport': {
            return {
                ...state,
                reportObj:undefined,
                workspace:'',
                report:''
            }
        }

        default:
            console.log('wrong reducer name..')
            return state;
    }
};

export default reducer;
