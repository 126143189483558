import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, Paper, Tab, Tabs,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DrawerContext } from '../../context';

import TenantPanel from './partials/TenantPanel';
import UserPanel from './partials/UserPanel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "80vh",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const Admin = ({role}) => {

  const classes = useStyles();
  const [tabValue,setTabValue] = useState(0);
  const { close, isOpen } = useContext(DrawerContext);

  // Disable drawer while the page is empty
  useEffect(() => {
    if (isOpen) {
      close();
    }
  }, [isOpen]);

  const handleChange = (tabIndex) => {
    setTabValue(tabIndex);
  };

  return (
    <Box m={4} width="auto" display="flex" flexDirection="column">
      <Paper>
        <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={(event,tabIndex)=>{handleChange(tabIndex)}}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label="Users" id="vertical-tab-1" aria-controls="vertical-tabpanel-1" value={0} />
              <Tab label="Tenancies" id="vertical-tab-2" aria-controls="vertical-tabpanel-2" value={1} disabled={role != 'SuperAdmin'} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <UserPanel />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <TenantPanel />
            </TabPanel>
          </div>
      </Paper>
    </Box>
  )
}

export default Admin