import React, { useState, useEffect } from 'react'

import {
    Box,
    Dialog,
    DialogTitle,
    TextField,
    Button,
} from '@material-ui/core'


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 350,
        },
        padding:8,
    },
}));

var defaultForm = {
    name: '',
    appId: '',
    appSecret: ''
}


const TenantForm = ({ state, ok, cancel, selected, title }) => {

    const [form, setForm] = useState({ ...defaultForm })

    const classes = useStyles();

    useEffect(() => {
        if (selected) {
            setForm((f)=>{ return{...f, ...selected }})
        }
    }, [selected, state])

    return (
        <Box m={4} width="auto">
            <Dialog disableBackdropClick aria-labelledby="simple-dialog-title" open={state}>
                <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
                <Box alignItems="center">
                    <form className={classes.root} noValidate autoComplete="off">
                        <Box>
                            <TextField
                                required
                                id="app-name-required"
                                label="Tenancy Name"
                                value={form.name}
                                onChange={(e) => { e.preventDefault(); setForm({ ...form, name: e.target.value }) }}
                            />
                        </Box>
                        <Box>
                            <TextField
                                required
                                id="app-id-required"
                                label="App Id"
                                value={form.appId}
                                onChange={(e) => { e.preventDefault(); setForm({ ...form, appId: e.target.value }) }}
                            />
                        </Box>
                        <Box>
                            <TextField
                                required
                                id="app-secret-required"
                                label="App Secret"
                                type="password"
                                value={form.appSecret}
                                onChange={(e) => { e.preventDefault(); setForm({ ...form, appSecret: e.target.value }) }}
                            />
                        </Box>
                    </form>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 16 }}
                        onClick={() => { ok(form); setForm(defaultForm) }}
                    >
                        OK
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: 16 }}
                        onClick={() => { setForm(defaultForm); cancel() }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </Box>
    )
}

export default TenantForm