import React, { useState, useEffect } from 'react'
import {
    Box,
    Dialog,
    DialogTitle,
    TextField,
    Button,
    FormControlLabel,
    Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
        padding:8,
    },
    inputSwitch: {
        marginLeft: 7
    }
}));

var defaultForm = {
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    approved: true,
    role: {id:0,name:'dummy'},
    tenancies: [{id:0,name:'dummy'}]
}


const UserForm = ({ state, ok, cancel, options }) => {

    const [form, setForm] = useState({...defaultForm})

    const classes = useStyles();

    const handleRole = (id) => {
        var foundRole = options.roles.filter(role => {
            return role.id == id
        })[0]
        setForm({ ...form, role: foundRole })
    }

    const handleTenancy = (event) => {
        const { options:opt } = event.target
        var tenancy = [];
        for (let i = 0, l = opt.length; i < l; i += 1) {
            if (opt[i].selected) {
                var foundTenancy = options.tenancies.find(tenancy => tenancy.id === parseInt(opt[i].value))
                tenancy.push(foundTenancy)
            }
        }
        setForm({...form, tenancies: tenancy})
    }

    useEffect(()=>{
        if(options.roles.length > 0 & options.tenancies.length > 0){
            setForm((f) => {return {
                ...f,
                role: options.roles[options.roles.length - 1],
                tenancies: [options.tenancies[0]]
            }})
        }

        if(options.user){
            setForm((f)=>{return {...f,...options.user}})
        }

    },[options,state])

    return (
        <Box m={4} width="auto">
            <Dialog disableBackdropClick aria-labelledby="simple-dialog-title" open={state}>
                <DialogTitle id="simple-dialog-title">{options.title}</DialogTitle>
                <Box alignItems="center">
                    <form className={classes.root} noValidate autoComplete="off">
                        <Box>
                        <TextField
                            required
                            id="first-name-required"
                            label="First Name"
                            value={form.firstName}
                            onChange={(e) => { e.preventDefault(); setForm({ ...form, firstName: e.target.value }) }}
                        />
                        <TextField
                            required
                            id="last-name-required"
                            label="Last Name"
                            value={form.lastName}
                            onChange={(e) => { e.preventDefault(); setForm({ ...form, lastName: e.target.value }) }}
                        />
                        </Box>
                        <Box>
                        <TextField
                            required
                            id="email-required"
                            label="Email (username)"
                            type="email"
                            value={form.username}
                            onChange={(e) => { e.preventDefault();  setForm({ ...form, username: e.target.value }) }}
                        />
                        <TextField
                            required
                            id="password-required"
                            label="Password"
                            type="password"
                            value={form.password}
                            onChange={(e) => {  e.preventDefault(); setForm({ ...form, password: e.target.value }) }}
                        />
                        </Box>
                        <Box>
                        <TextField
                            id="standard-select-role"
                            select
                            required
                            label="Role"
                            value={form.role?.id}
                            onChange={(e) => { e.preventDefault();  handleRole(e.target.value) }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Please select user role"
                        >
                            {options.roles.map((option) => (
                                <option key={`role-${option.id}`} value={option.id}>
                                    {option.roleName}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            id="standard-select-tenancy"
                            select
                            required
                            label="Tenancy"
                            disabled={options.tenancies ? options.tenancies.length < 2 ? true : false : true}
                            value={form.tenancies?.map(t=>{return t.id})}
                            onChange={(e) => { e.preventDefault();  handleTenancy(e) }}
                            SelectProps={{
                                native: true,
                                multiple: true,
                            }}
                            helperText="Please select user tenancy"
                        >
                            {options.tenancies.map((option) => (
                                <option key={`tenancy-${option.id}`} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                        </Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    className={classes.inputSwitch}
                                    checked={form.approved}
                                    onChange={(e) => {  e.preventDefault(); setForm({ ...form, approved: e.target.checked }) }}
                                    name="approved"
                                    inputProps={{ 'arial-label': 'primary checkbox' }}
                                />
                            }
                            label="Access"
                        />
                    </form>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 16 }}
                        onClick={() => { ok(form); setForm(defaultForm) }}
                    >
                        OK
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: 16 }}
                        onClick={() => { setForm(defaultForm); cancel() }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </Box>
    )
}

export default UserForm