import Axios from 'axios';

export default function restClient(){
    var url = process.env.REACT_APP_API_BASE;
    let api = Axios.create({
        baseURL: url
    })
    

    // Attach token on requests
    api.interceptors.request.use(
        async config => {
            const access_token = sessionStorage.getItem("accessToken")
            config.headers = {
                'Authorization': `Bearer ${access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            return config;
        },
        error => {
            Promise.reject(error)
    })

    
    /*
    *   API CONTROLLER
    */

    // Authenticate
    this.login = async (object) => {
        try{
            const response = await api.post(`/authentication`,object)
            return {
                status: response.status,
                data: response.data
            }
        } catch(ex){
            console.error(ex)
        }
    }


    // User CRUD
    this.userCreate = async (object) => {
        try{
            const response = await api.post(`/users`, object)
            return {
                status:response.status,
                data: response.body
            }
        } catch(ex){
            console.error(ex);
        }
    }
    this.userGet = async () => {
        try {
            const response = await api.get(`/users`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    this.userGetById = async (id) => {
        try {
            const response = await api.get(`/users/${id}`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    this.userUpdate = async (object) => {
        try{
            const response = await api.put(`/users`, object)
            return {
                status:response.status,
                data: response.body
            }
        } catch(ex){
            console.error(ex);
            console.log(ex)
        }
    }

    this.userDelete = async (id) => {
        try {
            const response = await api.delete(`/users/${id}`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    // User reset password
    this.userResetPassword = async (id) => {
        try {
            const response = await api.post(`/users/${id}/reset`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    // Roles
    this.rolesGet = async () => {
        try {
            const response = await api.get(`/users/roles`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    // Tenancy CRUD
    this.tenancyCreate = async (object) => {
        try{
            const response = await api.post(`/tenancy`, object)
            return {
                status:response.status,
                data: response.body
            }
        } catch(ex){
            console.error(ex)
        }
    }

    this.tenancyGet = async () => {
        try {
            const response = await api.get(`/tenancy`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    this.tenancyGetById = async (id) => {
        try {
            const response = await api.get(`/tenancy/${id}`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    this.tenancyUpdate = async (object) => {
        try{
            const response = await api.put(`/tenancy`, object)
            return {
                status:response.status,
                data: response.body
            }
        } catch(ex){
            console.error(ex);
        }
    }

    this.tenancyDelete = async (id) => {
        try {
            const response = await api.delete(`/tenancy/${id}`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    // Embed info
    this.getEmbedInfo = async() => {
        try{
            const response = await api.get(`/embed`)
            return {
                status: response.status,
                data: response.data
            }
        } catch(ex){
            console.error(ex);
        }
    }
}