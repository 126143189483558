const reducer = (state, action) => {
    switch (action.name) {

        case 'toggleLoading':
            return {
                ...state,
                loading: !state.loading,
            };

        case 'updateUserList': 
            return {
                ...state,
                users: action.payload
            }
            
        case 'updateRoleOptions': 
            return {
                ...state,
                roleOptions: action.payload
            }

        case 'updateTenancyOptions': 
            return {
                ...state,
                tenancyOptions: action.payload
            }

        case 'setSelectedUser':
            var user = state.users.find(x=> x.id === action.payload);

            return {
                ...state,
                selectedUser: user
            }

        case 'TogglePanel': 
            return {
                ...state,
                selectedUser: action.payload.value ? state.users.find(x=>x.id === action.payload.value) : undefined,
                panels:{
                    ...state.panels,
                    [action.payload.panel]: !state.panels[action.payload.panel]
                } 
            }

        default:
            console.log('wrong reducer name..')
            return state;
    }
};

export default reducer;