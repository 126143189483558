import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';

const EmptyStateMessage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size="1rem"/>
      <Typography
        color="textSecondary"
        style={{ textAlign: 'center', fontSize: '1rem' }}
      >
        Loading resource list.
      </Typography>
    </Box>
  );
};

export default EmptyStateMessage;
