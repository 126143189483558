import React, { Component, createContext } from 'react';

const initialState = {
  isOpen: false,
  open: () => {},
  close: () => {},
};

const DrawerContext = createContext(initialState);

class DrawerProvider extends Component {
  state = {
    isOpen: false,
    open: () => this.setState({ isOpen: true }),
    close: () => this.setState({ isOpen: false }),
  };

  render() {
    const { children } = this.props;
    return (
      <DrawerContext.Provider value={this.state}>
        {children}
      </DrawerContext.Provider>
    );
  }
}

const DrawerConsumer = DrawerContext.Consumer;

export { DrawerContext, DrawerProvider, DrawerConsumer };
