import React from 'react';
import ReactDOM from 'react-dom';

import registerServiceWorker from './registerServiceWorker';

import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';

import { App } from './containers';
import { DrawerProvider } from './context/DrawerContext';
import { NotificationProvider } from './context/NotificationContext';

const baseName = process.env.REACT_APP_BASE_NAME;

const MOUNT_NODE = document.getElementById('root');

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0D3958',
    },
    secondary: blueGrey,
  },
});

ReactDOM.render(
  <Router basename={baseName}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DrawerProvider>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </DrawerProvider>
    </ThemeProvider>
  </Router>,
  MOUNT_NODE,
);

registerServiceWorker();
