
function tokensToSession(payload){
    try{
        window.sessionStorage.setItem('userName',payload.username);
        window.sessionStorage.setItem('role',payload.role.roleName);
        window.sessionStorage.setItem('accessToken',payload.token.jwt);
        window.sessionStorage.setItem('validTo',payload.token.validTo);
        return(true)
    } catch(ex){
        return(false)
    }
}
module.exports = {tokensToSession}