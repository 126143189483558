const reducer = (state, action) => {
    switch (action.name) {

        case 'toggleLoading':
            return {
                ...state,
                loading: !state.loading,
            };

        case 'updateTenancyList': 
            return {
                ...state,
                tenancies: action.payload
            }
  
        case 'TogglePanel': 
            return {
                ...state,
                selectedTenancy: action.payload.value ? state.tenancies.find(x=>x.id == action.payload.value) : undefined,
                panels:{
                    ...state.panels,
                    [action.payload.panel]: !state.panels[action.payload.panel]
                } 
            }

        default:
            console.log('wrong reducer name..')
            return state;
    }
};

export default reducer;