import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoginForm } from '../../components/Auth';

import tokenHandler from '../../services/tokens.js';
import restClient from '../../services/apiClient'
var client = new restClient();

const useStyles = makeStyles(theme => ({
  page: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 64px)',
  },
}));

const Auth = ({ setAuth }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleAuth = async ({ emailAddress, password }) => {
    try {
      setLoading(true);

      var response = await client.login({
        "Username":emailAddress,
        "Password":password,
      })

      setLoading(false);

      if (response.status !== 200) { console.error('show error to user'); return;}

      tokenHandler.tokensToSession(response.data);
      setAuth(true);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Box className={classes.page}>
      <LoginForm handleAuth={handleAuth} loading={loading} />
      {/* <DrawerPortal>Drawer content for login page goes here</DrawerPortal> */}
    </Box>
  );
};

export default Auth;
